import { GetStaticProps, NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import NotFound from 'lms-ui/notFound';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { Routes } from 'business/router/routes';
import Button from 'lms-ui/button';
import Flex from 'lms-ui/flex';
import { RightArrowIcon } from 'lms-ui/icons';
import { useRouter } from 'next/router';
import styles from './index.module.scss';

export const Login: NextPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const goToDashboard = async () => {
    router.push({
      pathname: Routes.Home,
    });
  };

  return (
    <DocumentTitle title={t('title.notFound')}>
      <NotFound
        academyAlt={t('common.logoAcaAlt')}
        emblemAlt={t('common.emblemLogoAlt')}
        imageUri="/images/backgroundOlympic.jpg"
        cta={
          <Flex column justify="space-between" className={styles.ctaBox}>
            <div className={styles.notFound}>{t('pages.notFound.404')}</div>
            <div className={styles.context}>{t('pages.notFound.context')}</div>
            <Button
              type="primary"
              htmlType="button"
              onClick={goToDashboard}
              className={styles.buttonRedirect}
            >
              <Flex alignItems="center">
                <span aria-label={t('pages.notFound.ariaCta')}>
                  {t('pages.notFound.cta')}
                </span>
                <RightArrowIcon className={styles.buttonArrow} />
              </Flex>
            </Button>
          </Flex>
        }
      />
    </DocumentTitle>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale = '' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
};

export default Login;
